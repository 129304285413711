import "./PicturesSlideshow.css";
import { createSignal, For, onCleanup, onMount, Show } from "solid-js";
import { picturesSlideshow } from "../lib/picturesSlideshow";
import { Loading } from "./Loading";

export const PicturesSlideshow = (props) => {
  const [rootRef, setRootRef] = createSignal();
  const [thumbsRef, setThumbsRef] = createSignal();
  const [slideshow, setSlideshow] = createSignal();
  const [isLoading, setIsLoading] = createSignal(true);

  const handleClick = (pictureIndex) => () => {
    slideshow().select(pictureIndex);
    slideshow().pause();
  };

  onMount(() => {
    setRootRef(window.document.querySelector(props.element));
    setSlideshow(picturesSlideshow({
      rootNode: rootRef(),
      thumbsNode: thumbsRef(),
      interval: props.interval,
    }));
    slideshow().init().then(() => {
      setIsLoading(false);
      slideshow().play();
    });
  });

  onCleanup(() => slideshow?.().pause());

  return (
    <>
      <Show when={isLoading()}>
        <div id="pictures-loading">
          <Loading />
        </div>
      </Show>
      <div id="pictures-thumbnails" ref={setThumbsRef}>
        <For each={props.pictures}>
          {(picture, i) => (
            <img
              src={picture.src}
              alt={picture.alt}
              onClick={handleClick(i())}
            />
          )}
        </For>
      </div>
    </>
  );
};
