import "./Sidebar.css";
import { children, createSignal } from "solid-js";

export const Sidebar = (props) => {
  const child = children(() => props.children);

  const [collapsed, setCollapsed] = createSignal(false);
  const handleToggle = () => setCollapsed(!collapsed());

  return (
    <>
      <section class={["dark", ...[collapsed() ? "collapsed" : ""]].join(" ")}>
        {child}
      </section>
      <div
        id="sidebar-toggle"
        class={["dark", ...[collapsed() ? "shifted" : ""]].join(" ")}
        title="Vedi descrizione"
        onClick={handleToggle}
        role="button"
      >
        <span class={["arrow-icon", ...[collapsed() ? "open" : ""]].join(" ")}>
          <span class="left-bar"></span>
          <span class="right-bar"></span>
        </span>
      </div>
    </>
  );
};
