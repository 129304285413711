import "./RequestDialog.css";
import { createSignal, For, Show } from "solid-js";
import { RequestForm } from "./RequestForm";

export const RequestDialog = (props) => {
  const [result, setResult] = createSignal({});
  const handleClose = () => props.onClose?.();

  return (
    <>
      <Show when={result().messages}>
        <div
          class={["message", ...[result().success ? "success" : "error"]]
            .join(" ")}
        >
          <For each={result().messages}>
            {(message) => <p>{message}</p>}
          </For>
        </div>
        <Show when={result().success}>
          <div class="buttons single">
            <button
              type="button"
              title="Chiudi"
              name="close"
              onClick={handleClose}
            >
              Chiudi
            </button>
          </div>
        </Show>
      </Show>
      <Show when={!result().success}>
        <RequestForm onSubmit={setResult} onDismiss={handleClose} />
      </Show>
    </>
  );
};
