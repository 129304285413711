import "./Dialog.css";
import { Portal } from "solid-js/web";
import { children, createEffect, createSignal } from "solid-js";

export const Dialog = (props) => {
  const child = children(() => props.children);
  const [dialogRef, setDialogRef] = createSignal();
  createEffect(() =>
    props.open ? dialogRef?.().showModal() : dialogRef?.().close()
  );

  return (
    <Portal>
      <dialog ref={setDialogRef}>
        {child}
      </dialog>
    </Portal>
  );
};
