import "./Main.css";
import { RequestButton } from "./RequestButton";

const Dimensions = ({ children }) => (
  <span
    title="lunghezza &#215; profonditá &#215; altezza"
    style={{ cursor: "help" }}
  >
    {children} cm.
  </span>
);

export const Main = () => (
  <main>
    <p>
      Completamente <strong>realizzati a mano</strong> partendo da un foglio di
      {" "}
      <strong>alluminio</strong>: perfetti per il{" "}
      <strong>trasporto della legna</strong> da ardere.
    </p>
    <p>
      Angoli arrotondati, manico pieghevole,{" "}
      <strong>
        fondo rinforzato
      </strong>{" "}
      ed antigraffio.
    </p>

    <h2>Varianti dell&apos;alluminio</h2>
    <p>
      Corten, nero opaco liscio, argentato liscio o grigio bucciardato.
    </p>

    <h2>Forma e caratteristiche</h2>
    <p>
      &middot; <strong>Quadrato</strong>,{" "}
      <Dimensions>36&#215;29&#215;36</Dimensions>
      <br />
      &nbsp;&nbsp;peso: 2,2 Kg. &nbsp;
      <br />
      &nbsp;&nbsp;portata: 18/20 Kg. di legna.
    </p>
    <p>
      &middot; <strong>Rotondo</strong>,{" "}
      <Dimensions>35&#215;39&#215;36</Dimensions>
      <br />
      &nbsp;&nbsp;peso: 2,1 Kg. &nbsp;
      <br />
      &nbsp;&nbsp;portata: 16/18 Kg. di legna.
    </p>
    <RequestButton />
  </main>
);
