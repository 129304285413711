const wait = (timeout) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

const preloadImage = (src) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = resolve;
    image.onerror = reject;
    image.src = src;
  });

const getPictureFullSrc = (picture) =>
  picture.attributes.getNamedItem("src").value.replace("/thumb", "/full");

const changePicture = ({ rootNode, pictureNodes, step }) => {
  const currentPicture = pictureNodes[step];
  if (currentPicture !== undefined) {
    pictureNodes.forEach((picture) => picture.classList.remove("sel"));
    rootNode.style.backgroundImage = `url(${
      getPictureFullSrc(currentPicture)
    })`;
    currentPicture.classList.add("sel");
  }
};

const getPictursNodes = ({ thumbsNode }) => {
  const pictureNodes = [];
  thumbsNode.childNodes.values().forEach((node) => pictureNodes.push(node));
  return pictureNodes;
};

const nextStep = ({ pictureNodes, step }) =>
  step === pictureNodes.length - 1 ? 0 : step + 1;

const picturesSlideshow = ({ rootNode, thumbsNode, interval }) => {
  let execute = true;
  let pictureNodes = new Array(0);

  const loop = async () => {
    let step = 0;
    while (step <= pictureNodes.length - 1) {
      if (execute) {
        changePicture({ rootNode, pictureNodes, step });

        await wait(interval);
        step = nextStep({ pictureNodes, step });
      } else {
        step = pictureNodes.length;
      }
    }
  };

  const init = () => {
    rootNode.classList.add("pictures-slideshow");

    pictureNodes = getPictursNodes({ thumbsNode });

    return Promise.all(
      pictureNodes.map((pictureNode) =>
        preloadImage(getPictureFullSrc(pictureNode))
      ),
    );
  };

  const play = loop;

  const pause = () => execute = false;

  const select = (step) => changePicture({ rootNode, pictureNodes, step });

  const pictures = () => pictureNodes;

  return { init, play, pause, select, pictures };
};

export { picturesSlideshow };
