import "./Home.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { PicturesSlideshow } from "./components/PicturesSlideshow";
import { Sidebar } from "./components/Sidebar";

const THUMBS_URL = import.meta.url.includes("localhost:3000")
  ? "./images/secchi/thumb/"
  : "https://ik.imagekit.io/lpgfkag3i/ContenitoriLaurino/it/images/secchi/thumb/";

const PICTURES = [
  {
    src: `${THUMBS_URL}contenitore-per-legna.jpe`,
    alt: "Contenitore per legna nero opaco",
  },
  {
    src: `${THUMBS_URL}contenitori-porta-legna.jpe`,
    alt: "Contenitori per trasporto legna",
  },
  {
    src: `${THUMBS_URL}contenitore-in-alluminio.jpe`,
    alt: "Contenitore con manico pieghevole",
  },
  {
    src: `${THUMBS_URL}contenitori-in-alluminio-rinforzato.jpe`,
    alt: "Contenitori con fondo rinforzato",
  },
  {
    src: `${THUMBS_URL}contenitore-in-corten.jpg`,
    alt: "Contenitore in Corten",
  },
  {
    src: `${THUMBS_URL}portalegna-rettangolare-alluminio.jpg`,
    alt: "Portalegna rettagolare in alluminio",
  },
  {
    src: `${THUMBS_URL}contenitori-bucciardati-alluminio.jpg`,
    alt: "Contenitori in alluminio bucciardato",
  },
];

export const Home = () => (
  <>
    <Sidebar>
      <Header />
      <Main />
      <Footer />
    </Sidebar>
    <PicturesSlideshow element="body" pictures={PICTURES} interval={5000} />
  </>
);
