import "./RequestButton.css";
import { createSignal } from "solid-js";
import { Dialog } from "./Dialog";
import { RequestDialog } from "./RequestDialog";

export const RequestButton = () => {
  const [open, setOpen] = createSignal(false);
  const handleOpen = () => setOpen(() => true);
  const handleClose = () => setOpen(() => false);

  return (
    <>
      <button
        type="button"
        title="Richiedi informazioni"
        tabIndex="1"
        onClick={handleOpen}
      >
        Informazioni
      </button>
      <Dialog open={open()}>
        <RequestDialog onClose={handleClose} />
      </Dialog>
    </>
  );
};
