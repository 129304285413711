import "./RequestForm.css";
import { createSignal } from "solid-js";
import { submitContact } from "../services/contactService";

export const RequestForm = (props) => {
  const [formRef, setFormRef] = createSignal();

  const handleSubmit = (event) => {
    event.preventDefault();

    return submitContact(new FormData(formRef()))
      .then((response) => props.onSubmit?.(response))
      .catch(() =>
        props.onSubmit?.({ messages: ["Si è verificato un errore."] })
      );
  };

  return (
    <form ref={setFormRef} onSubmit={handleSubmit}>
      <fieldset>
        <div>
          <label for="contacts_name">Nome</label>
          <input
            type="text"
            id="contacts_name"
            class="input-field"
            name="contacts[name]"
            value=""
            required
            title="Il tuo nome"
          />
        </div>
        <div>
          <label for="contacts_tel">Telefono</label>
          <input
            type="tel"
            id="contacts_tel"
            class="input-field"
            name="contacts[tel]"
            value=""
            title="Recapito telefonico"
          />
        </div>
        <div>
          <label for="contacts_email">E-mail</label>
          <input
            type="email"
            id="contacts_email"
            class="input-field"
            name="contacts[email]"
            value=""
            title="Indirizzo di Posta Elettronica"
          />
        </div>
        <div>
          <label for="contacts_message">Messaggio</label>
          <textarea
            id="contacts_message"
            class="input-field"
            cols="20"
            rows="6"
            name="contacts[message]"
            title="Testo della richiesta"
          />
        </div>
        <div class="buttons">
          <button
            type="button"
            title="Chiudi"
            name="close"
            onClick={props.onDismiss}
          >
            Chiudi
          </button>
          <button
            type="submit"
            title="Invia la richiesta"
            name="send"
          >
            Invia
          </button>
        </div>
      </fieldset>
    </form>
  );
};
